<!-- 待缴费 -->
<template>
  <div id="leaveSchoolPayWay">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('leaveRefund.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div id="listLoading">
      <van-loading
        v-if="loading"
        type="spinner"
      />
    </div>
    <div class="outboxstyle">
      <div v-for="(item, index) in list" :key="index">
        <van-cell-group :class="[item.show?'group':'group2']" @click="choose(item)">
          <img :src="item.show==true?imageL[1] : imageL[2]" class="line">
          <van-cell :title="$t('leaveRefund.batchNumber')" :value="item.batchno" />
          <van-cell :title="$t('leaveRefund.studentNumber')" :value="item.idserial" />
          <van-cell :title="$t('leaveRefund.name')" :value="item.name" />
          <van-cell :title="$t('leaveRefund.refundAmount')" :value="'¥'+fenToYuan(item.transAmount)" />
          <van-cell :title="$t('leaveRefund.transferStatus')" :value="item.payStatus" :formatter="formatterTransferStatus" />
          <van-cell :title="$t('leaveRefund.processStatus')" :value="item.showStatusName" />
        </van-cell-group>
      </div>
    </div>
    <div class="bottom">
      <van-tag v-if="count !== 0" class="round-tag" type="danger" round>{{ count }}</van-tag>
      <van-button v-if="total === 0|| total === '0.00'" type="info" size="large" :loading="nextLoading" class="bottombutton" @click="nextStep">{{ $t('leaveRefund.applyRefund') }}</van-button>
      <van-button v-if="total !== 0&& total !=='0.00'" type="info" size="large" :loading="nextLoading" class="bottombutton" @click="nextStep">{{ $t('leaveRefund.applyRefund') }}（￥{{ total }}）</van-button>
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
import { getRefundApplicationList } from '@/api/refundApplication'
import { getDictDatasByTypes } from '@/api/dict'
import { fenToYuan } from '@/utils/commonUtils'
// import { getPersonCenterAccountList } from '@/api/personCenter'
import { Dialog } from 'vant'
import { Toast } from 'vant'
export default {
  name: 'LeaveSchoolPayWay',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast
  },
  data() {
    return {
      imgList: [
        require('../../assets/newImages/icon4.png'),
        require('../../assets/newImages/icon6.png'),
        require('../../assets/newImages/orderList4.png')

      ],
      imageL: [
        require('../../assets/newImages/home_icon6.png'),
        require('../../assets/newImages/right.png'),
        require('../../assets/newImages/rightbefore.png')
      ],
      paymentList: [],
      total: 0,
      show: false,
      money: '',
      moneyindex: '',
      count: 0,
      nextLoading: false,
      sectionAmount: 0,
      allAmount: 0,
      loading: false,
      list: [],
      payList: []
    }
  },
  watch: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
  },
  mounted() {
    this.getList()
    this.getTransferStatusList()
  },
  methods: {
    getList() {
      this.loading = true
      getRefundApplicationList().then(response => {
        this.list = response.data
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].show = false
        }
        this.loading = false
      })
    },
    choose(obj) {
      obj.show = !obj.show
      this.calculationData()
      return obj
    },
    nextStep() {
      if (this.payList.length < 1) {
        Toast.fail(this.$t('msg.selectItem'))
        return false
      } else {
        this.$router.push({ path: '/leaveSchoolToPay', query: { payWayitem: this.payList }})
      }
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/person'
      })
    },
    // 金钱转换
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 计算选中的数据
    calculationData() {
      this.count = 0
      this.allAmount = 0
      this.payList = []
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].show === true) {
          this.allAmount += this.list[i].transAmount
          this.count += 1
          this.payList.push(this.list[i])
        }
      }
      this.total = fenToYuan(this.allAmount)
    },
    // 转账导入名单状态格式化
    formatterTransferStatus(row, column) {
      var list = this.transferStatusList
      for (var i = 0; i < list.length; i++) {
        if ((row.payStatus) === list[i].key) {
          return list[i].value
        }
      }
    },
    getTransferStatusList() {
      getDictDatasByTypes('BILL_TYPE_TRANSFER_STATUS', window.localStorage.getItem('schoolcode'), this.$store.state.settings.dataSource).then(response => {
        this.transferStatusList = response.data.BILL_TYPE_TRANSFER_STATUS
      })
    }
  }
}
</script>
<style scoped lang="scss">
#leaveSchoolPayWay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .van-switch {
    font-size: 24px;
    height: 22px;
    line-height: 24px;
  }
  .van-cell__value {
    height: 24px;
  }
}
.outboxstyle {
  padding-top: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  min-height: 100px;
}
.boxstyle {
  font-size:16px;
  height:50px;
  background:#fff;
  border-radius:10px 10px 0px 0px;
  padding:0px 30px
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .round-tag{
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 100;
  }
  .van-tag--danger{
    background-color: #FF4444;
  }
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
.group {
  padding: 20px 0 10px;
  border-radius: 10px;
  margin: 5px 12px;
  border:1px solid #3BA4F2
}
.group2 {
  padding: 20px 0 10px;
  border-radius: 10px;
  margin: 5px 12px;
  border:1px solid #eeeeee
}
.line{
  width:40px;
  position:absolute;
  right: -1px;
  top: -1px;
  z-Index:2;
}
.total{
  font-size:18px;
  line-height:70px;
  font-weight:700;
  display: flex;
  align-items: center;
  img{
    align-items: center;
    margin:0 20px 0 10px;
    width:40px;
  }
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
.van-switch__node {
  width: 20px !important;
  height: 20px !important;
  left: 2px;
}
#listLoading .van-loading{
    position: fixed !important;
    color: #c8c9cc !important;
    font-size: 0;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background: #333333;
    opacity: 0.8;
    border-radius: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#listLoading .van-loading__spinner {
      color: rgb(255, 255, 255);
      width: 40px !important;
      height: 40px !important;
      margin: 20px !important;
  }
</style>
